export default function({ app }) {
  // const { interval, endpoint } = app.$auth.options.refreshJWT
  // const computedInterval = 1000 * 60 * interval

  if (app.$auth.loggedIn) {
    // setInterval(function() {
    //   $axios({
    //     method: endpoint.method,
    //     url: endpoint.url
    //   }).then(({ data }) => {
    //     const bearerToken = `Bearer ${data.data.access_token}`
    //     app.$auth.setToken('local', bearerToken)
    //     $axios.setToken(bearerToken)
    //   })
    // }, computedInterval)
  }
}
