import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f204d3e = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _62b85f6e = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _4a430783 = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _64a241f2 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _ca7e7cba = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _094a1d84 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _23230ae6 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _52be1b0d = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _b999483c = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _4c3e471d = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _42efbf75 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _56e845ce = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _42859a3c = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _115735f6 = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _52377f70 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _b779e724 = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _c4a71d26 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _006e64ba = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _13f45974 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _634ba20f = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _71273fb5 = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _412a8cb5 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _044a2f11 = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _62d36c07 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _77a20c0a = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _c1733824 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _da492dcc = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _8b9a8fc4 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _41b693a2 = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _1e130a82 = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _8fa7eb1e = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _24ba5eec = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _2bd6267c = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _159232f2 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _35b80fc6 = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _ae2f9510 = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _5fb1be3a = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _a6a00466 = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _4bb71796 = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _103321c1 = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _837ecdd6 = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _5fdecb45 = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _40c6c832 = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _bc59b872 = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _3d989b43 = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _53480487 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _649480a1 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _02c85626 = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _73765a3e = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _3d97e608 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _8199a34e = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _53b48a8b = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _3668d1e0 = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _7ad60525 = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _319631c3 = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _bd76f2d8 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _97925576 = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _79481c5e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ai-reports",
    component: _6f204d3e,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _62b85f6e,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _4a430783,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _64a241f2,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _ca7e7cba,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _094a1d84,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _23230ae6,
    name: "feedback-funnel"
  }, {
    path: "/invoices",
    component: _52be1b0d,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _b999483c,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _4c3e471d,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _42efbf75,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _56e845ce,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _42859a3c,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _115735f6,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _52377f70,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _b779e724,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _c4a71d26,
    name: "my-team"
  }, {
    path: "/news",
    component: _006e64ba,
    name: "news"
  }, {
    path: "/notifications",
    component: _13f45974,
    name: "notifications"
  }, {
    path: "/payment-updates",
    component: _634ba20f,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _71273fb5,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _412a8cb5,
    name: "profile"
  }, {
    path: "/profiles",
    component: _044a2f11,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _62d36c07,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _77a20c0a,
    name: "settings"
  }, {
    path: "/stats",
    component: _c1733824,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _da492dcc,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _8b9a8fc4,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _41b693a2,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _1e130a82,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _8fa7eb1e,
    name: "wink-replies"
  }, {
    path: "/manuals/new",
    component: _24ba5eec,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _2bd6267c,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _159232f2,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _35b80fc6,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _ae2f9510,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _5fb1be3a,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _a6a00466,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _4bb71796,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _103321c1,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _837ecdd6,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _5fdecb45,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _40c6c832,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _bc59b872,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/activity-logs/:type?",
    component: _3d989b43,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _53480487,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _649480a1,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _02c85626,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _73765a3e,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _3d97e608,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _8199a34e,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _53b48a8b,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _3668d1e0,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _7ad60525,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _319631c3,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _bd76f2d8,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _97925576,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _79481c5e,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
